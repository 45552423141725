import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SmartLink from '../components/SmartLink'

const sitesQuery = graphql`
  query sitePages {
    allStructure {
      nodes {
        uid
        name1
      }
    }
  }
`

const Sitemap = () => {
  const sites = useStaticQuery(sitesQuery)
  return (
    <Layout title='Sitemap'>
      <h1>Sitemap</h1>
      <h2>Structures</h2>
      <ul>
        {sites.allStructure.nodes.map((site) => (
          <React.Fragment key={site.uid}>
            <li>
              <SmartLink to={`/structures/${site.uid}`}>{site.name1}</SmartLink>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </Layout>
  )
}

export default Sitemap
